
export default function ArticleBodyJanuaryFourteenth2025() {
    return (
        <div className="relative overflow-hidden py-16 bg-gray-50">
            <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full">
                <div className="relative mx-auto h-full max-w-prose text-lg" aria-hidden="true">
                    <svg
                        className="absolute top-16 left-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="unique-pattern-1"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect
                                    x={0}
                                    y={0}
                                    width={4}
                                    height={4}
                                    className="text-rose-300"
                                    fill="currentColor"
                                />
                            </pattern>
                        </defs>
                        <rect
                            width={404}
                            height={384}
                            fill="url(#unique-pattern-1)"
                        />
                    </svg>
                    <svg
                        className="absolute bottom-20 right-full translate-x-32 transform"
                        width={404}
                        height={384}
                        fill="none"
                        viewBox="0 0 404 384"
                    >
                        <defs>
                            <pattern
                                id="unique-pattern-2"
                                x={0}
                                y={0}
                                width={20}
                                height={20}
                                patternUnits="userSpaceOnUse"
                            >
                                <rect
                                    x={0}
                                    y={0}
                                    width={4}
                                    height={4}
                                    className="text-gray-300"
                                    fill="currentColor"
                                />
                            </pattern>
                        </defs>
                        <rect
                            width={404}
                            height={384}
                            fill="url(#unique-pattern-2)"
                        />
                    </svg>
                </div>
            </div>

            <div className="relative px-6 lg:px-8">
                <div className="mx-auto max-w-prose text-lg">
                    <h1>
                        <span className="block text-center text-lg font-semibold text-rose-600">
                            [Modern Announcement Title]
                        </span>
                        <span className="mt-4 block text-center text-6xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                            Stylish Article Layout Example
                        </span>
                        <span className="mt-2 block text-center text-lg text-gray-700 sm:text-2xl">
                            A demonstration of sleek design and modern aesthetics.
                        </span>
                    </h1>

                    <div className="flex justify-center mt-8">
                        <img
                            className="rounded-lg shadow-lg"
                            src="/placeholder.jpg"
                            alt="placeholder"
                            style={{ width: '60%', height: 'auto' }}
                        />
                    </div>

                    <p className="mt-8 text-xl leading-8 text-gray-600">
                        Placeholder text to showcase how text content will look within the layout. This section is
                        perfect for engaging introductions or key points.
                    </p>

                    <div className="mt-12">
                        <h2 className="text-3xl font-bold text-rose-600">Section Header</h2>
                        <p className="mt-4 text-lg text-gray-700 leading-8">
                            More placeholder content to demonstrate detailed paragraphs with proper spacing and
                            readability. Add engaging descriptions here.
                        </p>
                    </div>

                    <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
                        <div className="p-6 border rounded-lg shadow-sm">
                            <h3 className="text-2xl font-semibold text-rose-500">Feature 1</h3>
                            <p className="mt-2 text-gray-600">
                                Description of Feature 1, illustrating how you can emphasize unique selling points.
                            </p>
                        </div>
                        <div className="p-6 border rounded-lg shadow-sm">
                            <h3 className="text-2xl font-semibold text-rose-500">Feature 2</h3>
                            <p className="mt-2 text-gray-600">
                                Description of Feature 2, highlighting interactive and engaging elements.
                            </p>
                        </div>
                    </div>

                    <div className="mt-12 text-center">
                        <a
                            href="/assets/articles/11.23.23/Space Tourism (1).pdf"
                            className="inline-block bg-rose-600 text-white text-lg font-semibold px-6 py-3 rounded-lg shadow-md hover:bg-rose-500"
                        >
                            Call to Action
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}
