export default function ArticleBodyJanuaryFourteenth2025() {
    return (
        <div className="relative overflow-hidden py-16">
            <div className="hidden lg:absolute lg:inset-y-0 lg:block lg:h-full lg:w-full">
                {/* Keep existing SVG patterns here */}
            </div>

            <div className="relative px-6 lg:px-8">
                <div className="mx-auto max-w-prose text-lg">
                    <h1>
                        <span className="block text-center text-lg font-semibold text-rose-600">
                            Important Announcement Empowering Volunteering!
                        </span>
                        <span className="mt-4 block text-center text-6xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                            The Presidential Volunteer Service Award
                        </span>
                    </h1>

                    <div className="flex flex-col items-center mt-8">
                        <img
                            className="rounded-lg shadow-lg"
                            src="./assets/pvsa.png"
                            alt="Presidential Volunteer Service Award medal and pin"
                            style={{ width: '60%', height: 'auto' }}
                        />
                        <p className="text-sm text-gray-500 mt-2 text-center">
                            The Presidential Volunteer Service Award medallion (Source: 
                            <a href="https://www.presidentialserviceawards.gov" 
                            className="text-rose-600 hover:underline ml-1">
                                PVSA
                            </a>)
                        </p>
                    </div>

                    {/* Rest of the content remains the same */}
                    <p className="mt-8 text-xl leading-8 text-gray-600">
                        We're thrilled to announce that STEMEPA has been officially certified as a 
                        certifying organization for the <strong>Presidential Volunteer Service Award (PVSA)</strong>. 
                        This prestigious national honor recognizes outstanding volunteer service and civic participation.
                    </p>

                    <div className="mt-12">
                        <h2 className="text-3xl font-bold text-rose-600">What This Means</h2>
                        <p className="mt-4 text-lg text-gray-700 leading-8">
                            The PVSA, established in 2003, is the premier volunteer awards program in the United States. 
                            As a certifying organization, we can now:
                        </p>
                        
                        <div className="mt-8 grid grid-cols-1 md:grid-cols-2 gap-8">
                            <div className="p-6 border rounded-lg shadow-sm">
                                <h3 className="text-2xl font-semibold text-rose-500">Recognize Excellence</h3>
                                <p className="mt-2 text-gray-600">
                                    Award bronze, silver, and gold medals to volunteers who meet annual hour requirements
                                </p>
                            </div>
                            <div className="p-6 border rounded-lg shadow-sm">
                                <h3 className="text-2xl font-semibold text-rose-500">Amplify Impact</h3>
                                <p className="mt-2 text-gray-600">
                                    Validate volunteer hours through official government-recognized channels
                                </p>
                            </div>
                        </div>
                    </div>

                    <div className="mt-12">
                        <h2 className="text-3xl font-bold text-rose-600">How It Works</h2>
                        <p className="mt-4 text-lg text-gray-700 leading-8">
                            Volunteers can earn awards based on annual service hours:
                        </p>
                        <ul className="mt-4 list-disc pl-6 text-gray-600">
                            <li className="mt-2"><strong>Young Adults (16+):</strong> Bronze (100-174 hrs), Silver (175-249 hrs), Gold (250+ hrs)</li>
                            <li className="mt-2"><strong>Adults:</strong> Bronze (100-249 hrs), Silver (250-499 hrs), Gold (500+ hrs)</li>
                        </ul>
                    </div>

                    <div className="mt-12 space-y-4">
                        <h3 className="text-2xl font-semibold text-rose-600">Learn More</h3>
                        <ul className="list-none space-y-2">
                            <li>
                                <a href="https://presidentialserviceawards.gov/" 
                                   className="text-rose-600 hover:underline flex items-center">
                                    <span>Official PVSA Website →</span>
                                </a>
                            </li>
                            <li>
                                <a href="https://presidentialserviceawards.gov/eligibility#volunteers" 
                                   className="text-rose-600 hover:underline flex items-center">
                                    <span>Learn More Here →</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div className="mt-12 text-center">
                        <a
                            href="/volunteer-signup"
                            className="inline-block bg-rose-600 text-white text-lg font-semibold px-6 py-3 rounded-lg shadow-md hover:bg-rose-500"
                        >
                            Start Tracking Your Hours
                        </a>
                    </div>
                </div>
            </div>
        </div>
    );
}