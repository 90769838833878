import FAQ from "../components/FAQ";
import Footer from "../components/Footer";
import Header from "../components/Header";
import Navbar from "../components/Navbar";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";

export default function About() {
  return (
    <div className="bg-background">
      {/* Navbar */}
      <Navbar />

      {/* Header */}
      <Header
        heading="Who Are We?"
        text="Our organization, founded by passionate students, aims to ignite a love for STEM and foster widespread literacy in science, technology, engineering, and math throughout Pennsylvania. By collaborating with local businesses and STEM-focused organizations, we provide unique and engaging opportunities designed to inspire and empower individuals, especially young minds. Join us at our next event or visit our events page to discover more about the exciting experiences we offer!"
      />

      {/* Divider */}
      {/* Meet Our Executive Team Section */}
      <section className="text-center py-8">
      <h2 class="text-[calc(3rem_+_2vw)] font-bold xl:text-[3rem] leading-[1.2] mb-3 xl:!px-[4.5rem] lg:!px-[4.5rem] text-rose-500">
      Meet our team:  By Students, For Students.
</h2>

<Swiper
          modules={[Pagination, Navigation]}
          spaceBetween={30}
          slidesPerView={1}
          navigation
          pagination={{ clickable: true }}
          breakpoints={{
            640: { slidesPerView: 2, spaceBetween: 40 },
            768: { slidesPerView: 3, spaceBetween: 50 },
            1024: { slidesPerView: 4, spaceBetween: 60 },
          }}
          className="w-full max-w-6xl mx-auto"
        >
          {/* Team Member 1 */}
          <SwiperSlide>
            <div className="p-8 bg-white rounded-lg shadow-lg max-w-[350px] h-[420px] mx-auto">
              <img
                className="rounded-full w-[120px] h-[120px] mx-auto mb-6 object-cover"
                src="/assets/KP.JPG"
                alt="Krish Patel"
              />
              <h4 className="mb-2 text-lg font-bold">Krish Patel</h4>
              <div className="text-sm uppercase tracking-wide font-bold text-gray-400 mb-4">
                Co-President and Lead Chapter Director
              </div>
              <p className="text-sm text-gray-600">
                Junior at Council Rock South High School. Published Medical
                Researcher, Certified EMT, and American Academy of Neurology Finalist.
              </p>
            </div>
          </SwiperSlide>

          {/* Team Member 2 */}
          <SwiperSlide>
          <div className="p-8 bg-white rounded-lg shadow-lg max-w-[350px] h-[420px] mx-auto">
          <img
                className="rounded-full w-[120px] h-[120px] mx-auto mb-6 object-cover"
                src="/assets/IMG_2200.JPG"
                alt="Benjamin Wang"
              />
              <h4 className="mb-2 text-lg font-bold">Benjamin Wang</h4>
              <div className="text-sm uppercase tracking-wide font-bold text-gray-400 mb-4">
                Co-President And Website / Operations Manager
              </div>
              <p className="text-sm text-gray-600">
                Junior at Council Rock South High School. PJAS 1st Place, Intern at Andrena / DAWN Internet, and NCTE First Class Writer.
              </p>
            </div>
          </SwiperSlide>

          {/* Team Member 3 */}
          <SwiperSlide>
          <div className="p-8 bg-white rounded-lg shadow-lg max-w-[350px] h-[420px] mx-auto">
          <img
                className="rounded-full w-[120px] h-[120px] mx-auto mb-6 object-cover"
                src="/assets/IMG_6273.png"
                alt="Jane Doe"
              />
              <h4 className="mb-2 text-lg font-bold">Kameron Dalessandro</h4>
              <div className="text-sm uppercase tracking-wide font-bold text-gray-400 mb-4">
                Vice-President and PR / Social Media Manager
              </div>
              <p className="text-sm text-gray-600">
                Junior at Council Rock High School South. Student Council Representative, Distinguished Honor Roll, and FBLA State Finalist.  
              </p>
            </div>
          </SwiperSlide>
        </Swiper>
      </section>

      {/* FAQ Section */}
      <FAQ />

      {/* Footer */}
      <Footer />
    </div>
  );
}
