export default function ContactForm() {
  return (
    <div>
      <form
        name="contact"
        method="POST"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        encType="multipart/form-data"  // Required for file uploads
        className="mt-6 grid grid-cols-1 gap-y-6 sm:grid-cols-2 sm:gap-x-8"
      >
        {/* Hidden field for Netlify form detection */}
        <input type="hidden" name="form-name" value="contact" />

        {/* Spam prevention honeypot */}
        <p className="hidden">
          <label>
            Don’t fill this out: <input name="bot-field" />
          </label>
        </p>

        <div>
          <label htmlFor="first-name" className="block text-sm font-medium">
            First Name
          </label>
          <input
            type="text"
            name="first-name"
            id="first-name"
            autoComplete="given-name"
            required
            className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
          />
        </div>

        <div>
          <label htmlFor="last-name" className="block text-sm font-medium">
            Last Name
          </label>
          <input
            type="text"
            name="last-name"
            id="last-name"
            autoComplete="family-name"
            required
            className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
          />
        </div>

        <div>
          <label htmlFor="email" className="block text-sm font-medium">
            Email
          </label>
          <input
            id="email"
            name="email"
            type="email"
            autoComplete="email"
            required
            className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
          />
        </div>

        <div>
          <label htmlFor="resume" className="block text-sm font-medium">
            Upload Resume (Optional)
          </label>
          <input
            type="file"
            name="resume"
            id="resume"
            className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
          />
        </div>

        <div className="sm:col-span-2">
          <label htmlFor="message" className="block text-sm font-medium">
            Message (please describe yourself)
          </label>
          <textarea
            id="message"
            name="message"
            rows={4}
            required
            className="bg-gray-300 block w-full rounded-md py-3 px-4 text-black shadow-sm focus:border-rose-500 focus:ring-rose-500"
          />
        </div>

        <div className="sm:col-span-2 sm:flex sm:justify-end">
          <button
            type="submit"
            className="inline rounded-md border border-transparent bg-rose-500 px-5 py-3 text-base font-medium text-white shadow hover:bg-rose-600 focus:outline-none focus:ring-2 focus:ring-rose-500 focus:ring-offset-2 sm:px-10"
          >
            Submit
          </button>
        </div>
      </form>
    </div>
  );
}
