import Navbar from "../components/Navbar";

import Footer from "../components/Footer";
import GridList1 from "../components/GridList1";
import Header from "../components/Header";
import GridList2 from "../components/GridList2";

export default function Resources() {
  return (
    <div className="bg-background">
      <Navbar />
      <Header
        heading="STEM Competitions and Research Programs"
        text= "Are you a high schooler or middle schooler looking to take the next step forward in your STEM pursuits?  Looking to gain merit and national recognition?  Throughout our own high school careers, we have pursued all sorts of educational competitions and programs that have gathered us invaluable knowledge and opportunities.  Here is the list of the best programs that we can ALL personally attest too!"
      />
      {/*add grid list of competitions*/}
      <Header
        heading="Resources K-6"
        text= "Check out our carefully curated resources for kindergarten to sixth-grade students."
      />
      <GridList1/>
      <Header
        heading="Resources 7-12"
        text="Check out our carefully curated eductational resources for seventh to twelfth-grade students. "
        className="-mt-14"
      />
      <GridList2/>

      <Footer />
    </div>
  );
}
